const FileBg = ({ gradientColors }) => {
    const svgString = `
       <svg
        width="181"
        height="181"
        viewBox="0 0 181 181"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="227.072"
          cy="-114.924"
          r="133.083"
          transform="rotate(-31.9829 227.072 -114.924)"
          fill="url(#paint0_linear_4435_20929)"
          stroke="url(#paint1_linear_4435_20929)"
        />
        <circle
          cx="57.5745"
          cy="-9.07935"
          r="133.083"
          transform="rotate(-31.9829 57.5745 -9.07935)"
          fill="url(#paint2_linear_4435_20929)"
          stroke="url(#paint3_linear_4435_20929)"
        />
        <circle
          cx="159.305"
          cy="-35.0999"
          r="133.083"
          transform="rotate(-31.9829 159.305 -35.0999)"
          fill="url(#paint4_linear_4435_20929)"
          stroke="url(#paint5_radial_4435_20929)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4435_20929"
            x1="227.072"
            y1="-248.507"
            x2="227.072"
            y2="18.6589"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-opacity="0.4" stop-color="${gradientColors[0]}" />
            <stop offset="1" stop-color="${gradientColors[1]}" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_4435_20929"
            x1="345.83"
            y1="-224.725"
            x2="78.8181"
            y2="-125.425"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="${gradientColors[2]}" stop-opacity="0" />
            <stop offset="1" stop-color="${gradientColors[3]}" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_4435_20929"
            x1="57.5745"
            y1="-142.662"
            x2="57.5745"
            y2="124.504"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-opacity="0.4" stop-color="${gradientColors[0]}" />
            <stop offset="1" stop-color="${gradientColors[1]}" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_4435_20929"
            x1="197.489"
            y1="-22.3604"
            x2="-111.991"
            y2="46.207"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="${gradientColors[0]}" />
            <stop offset="1" stop-color="${gradientColors[1]}" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_4435_20929"
            x1="159.305"
            y1="-168.683"
            x2="159.305"
            y2="98.4832"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="${gradientColors[2]}" />
            <stop offset="1" stop-color="${gradientColors[3]}" stop-opacity="0" />
          </linearGradient>
          <radialGradient
            id="paint5_radial_4435_20929"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(159.305 -35.0999) rotate(89.968) scale(276.741)"
          >
            <stop offset="0.046875" stop-color="${gradientColors[0]}" stop-opacity="0" />
            <stop offset="1" stop-color="${gradientColors[1]}" />
          </radialGradient>
        </defs>
      </svg>
    `;
  
    const encodedSVG = encodeURIComponent(svgString.trim());
  
    return `url("data:image/svg+xml,${encodedSVG}")`;
  };

export default FileBg;